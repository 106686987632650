
function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
function URLToArray(url) {
  const request = {};
  const arr = [];
  const pairs = url.substring(url.indexOf('?') + 1).split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    // check we have an array here - add array numeric indexes so the key elem[] is not identical.
    if (endsWith(decodeURIComponent(pair[0]), '[]')) {
      const arrName = decodeURIComponent(pair[0]).substring(
        0,
        decodeURIComponent(pair[0]).length - 2,
      );

      if (!(arrName in arr)) {
        arr.push(arrName);
        arr[arrName] = [];
      }

      arr[arrName].push(decodeURIComponent(pair[1]));
      request[arrName] = arr[arrName];
    } else {
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return request;
}

const params = URLToArray(window.location.href);

if (params.token) {
  window.HiJiffyWidget = window.HiJiffyWidget || {};
  window.HiJiffyWidget.Token = params.token;
  window.HiJiffyWidget.fullscreen = true;
  window.HiJiffyWidget.direct = true;

  if (params.key && params.key !== '') {
    window.HiJiffyWidget.userProfile = window.HiJiffyWidget.userProfile || {};
    window.HiJiffyWidget.userProfile.user_id = params.key;
  }
  if (params.first_name && params.first_name !== '') {
    window.HiJiffyWidget.userProfile = window.HiJiffyWidget.userProfile || {};
    window.HiJiffyWidget.userProfile.first_name = params.first_name;
  }
  if (params.last_name && params.last_name !== '') {
    window.HiJiffyWidget.userProfile = window.HiJiffyWidget.userProfile || {};
    window.HiJiffyWidget.userProfile.last_name = params.last_name;
  }
  if (params.email && params.email !== '') {
    window.HiJiffyWidget.userProfile = window.HiJiffyWidget.userProfile || {};
    window.HiJiffyWidget.userProfile.email = params.email;
  }
  if (params.notes && params.notes !== '') {
    window.HiJiffyWidget.userProfile = window.HiJiffyWidget.userProfile || {};
    window.HiJiffyWidget.userProfile.notes = params.notes;
  }
  if (params.headerIcons) {
    window.HiJiffyWidget.showHeaderIcons = params.headerIcons === 'true';
  }

  if (params.locale && params.locale !== '') {
    window.HiJiffyWidget.locale = params.locale;
  }
  const js = document.createElement('script');
  const fjs = document.getElementsByTagName('script')[0];
  js.async = true;
  js.src = `app.js?t=${Math.floor(new Date().getTime() / 60000)}`;
  fjs.parentNode.insertBefore(js, fjs);
}
